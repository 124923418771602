import React from 'react'
import Logo from '../Logo'
import './AppHeader.css'

function AppHeader() {
  return (
    <header className="App-header">
      <div className="App-logo">
        <Logo />
      </div>
      <h2>The Editimator</h2>
    </header>
  )
}

export default AppHeader
