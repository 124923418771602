import React from 'react'

const Logo = props => (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 1200 1200">
<g>
	<path d="M600,21.5c-319.5,0-578.5,259-578.5,578.5s259,578.5,578.5,578.5s578.5-259,578.5-578.5S919.5,21.5,600,21.5z M600,123
		c77.87,0,141,63.13,141,141s-63.13,141-141,141s-141-63.13-141-141S522.13,123,600,123z M264,741c-77.87,0-141-63.13-141-141
		s63.13-141,141-141s141,63.13,141,141S341.87,741,264,741z M600,1077c-77.87,0-141-63.13-141-141s63.13-141,141-141
		s141,63.13,141,141S677.87,1077,600,1077z M936,741c-65.79,0-121.05-45.06-136.62-106H600c-19.33,0-35-15.67-35-35s15.67-35,35-35
		h199.38c15.57-60.94,70.83-106,136.62-106c77.87,0,141,63.13,141,141S1013.87,741,936,741z"/>
	<path d="M1025,600c0-19.33-15.67-35-35-35H799.38c-2.86,11.19-4.38,22.92-4.38,35s1.52,23.81,4.38,35H990
		C1009.33,635,1025,619.33,1025,600z"/>
</g>
</svg>)

export default Logo