import React from 'react'
import './AppHeader.css'

function SalesIntro() {
  return (
    <div className="SalesIntro">
      <h1>An editing hours calculator</h1>
      <h3>A tool for estimating video editing hours</h3>
      <h4>Need to calculate estimated editing hours for a commercial? a corporate video? a music video? a short film? a feature film?</h4>
      <h3>Give your clients an estimated editing cost in seconds!</h3>
    </div>
  )
}

export default SalesIntro
