import React from 'react'
import './App.css'
import AppHeader from './components/AppHeader'
import SalesIntro from './components/SalesIntro'

function App() {
  return (
    <div className="App">
      <AppHeader />

      <main>
        <SalesIntro />
      </main>

    </div>
  );
}

export default App
